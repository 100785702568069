import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Container from 'layout/Container';
import { DangerouslySetInnerHtml } from 'layout';

import BreadCrumbs from 'common/BreadCrumbs';
import Title from 'common/Title';
import SimplePageBanner from 'components/SimplePageBanner';
import FaqSection from 'components/FaqSection';

import { SimplePageBodyProps, IPropsSimplePage } from './models';

import './SimplePage.scss';
import './ShadowSimplePage.scss';

export const SHOW_RESULTS_SCROLL = 'showResultsScrollTarget';

const SimplePageBody: FC<SimplePageBodyProps> = ({ body }) => {
  const { accordionItemText, faqSection } = body;
  if (faqSection) {
    return <FaqSection data={faqSection} />;
  }
  if (accordionItemText) {
    return (
      <div className="accordion-item-text">
        <DangerouslySetInnerHtml
          className="text-block"
          html={accordionItemText.accordionItemText}
        />
      </div>
    );
  }

  return null;
};

const SimplePage: FC<IPropsSimplePage> = ({ data: { pageData }, pageContext: { breadcrumbs } }) => {
  const { seo, defaultCompositions, text, title, banner, body } = pageData;

  return (
    <Layout defaultCompositions={defaultCompositions} seo={seo}>
      <div data-test="SimplePage" className="simple-page">
        <Container>
          <BreadCrumbs breadcrumbs={breadcrumbs} />
        </Container>

        <Container>
          {title && !banner?.length ? (
            <Title dataTestAttr="SimplePageTitle" Tag="h1" className="simple-page__title">
              {title}
            </Title>
          ) : null}
          {banner?.length ? <SimplePageBanner banner={banner[0]} /> : null}

          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define, react/no-array-index-key */}
          {body?.length
            ? body.map((item, index) => <SimplePageBody key={index} body={item} />)
            : null}

          <DangerouslySetInnerHtml html={text} className="simple-page__content" />
        </Container>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($link: String!) {
    pageData: simplePage(link: { eq: $link }) {
      seo {
        ...FragmentSeo
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
      title
      text
      banner {
        topBanner {
          description
          title
          image {
            altText
            fallbackUrl
          }
        }
      }
      body {
        accordionItemText {
          accordionItemText
        }
        faqSection {
          text
          accordion {
            faqAccordionItem {
              label
              link {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default SimplePage;
