import React, { FC } from 'react';

import classNames from 'classnames';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { SimplePageBannerProps } from './models';
import './SimplePageBanner.scss';

const SimplePageBanner: FC<SimplePageBannerProps> = ({ banner }) => {
  const { title, description, image } = banner?.topBanner ?? {};
  const { fallbackUrl, altText } = image ?? {};

  return (
    <div className="simple-page-banner">
      <div
        className={classNames('simple-page-banner__text', {
          'simple-page-banner__text--noimage': !fallbackUrl,
        })}
      >
        <DangerouslySetInnerHtml html={title} className="simple-page-banner__title" />
        <DangerouslySetInnerHtml html={description} className="simple-page-banner__description" />
      </div>
      {fallbackUrl ? (
        <div className="simple-page-banner__container">
          <img src={fallbackUrl} alt={altText ?? ''} className="simple-page-banner__image" />
          <div className="simple-page-banner__gradient" />
        </div>
      ) : (
        <div className="simple-page-banner__noimage" />
      )}
    </div>
  );
};

export default SimplePageBanner;
