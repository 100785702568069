import React, { FC } from 'react';

import { FaqSectionProps } from './models';

import './FaqSection.scss';

const FaqSection: FC<FaqSectionProps> = ({ data }) => (
  <div className="faq-section">
    {data?.accordion?.length ? (
      <div className="faq-section__accordion">
        {data.accordion.map((item, index) => (
          <a
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            href={item?.faqAccordionItem?.link?.url ?? ''}
            className="faq-section__accordion__item"
          >
            {item.faqAccordionItem?.label}
          </a>
        ))}
      </div>
    ) : null}
  </div>
);
export default FaqSection;
